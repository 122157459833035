<template>
   <div class="page_section" v-if="page.bySlug.id">
      <MobileTitle :title="page.bySlug.title"/>
      <template v-for="(element, index) in page.bySlug.content_elements">
         <ContentElements :content="element" :key="index"/>
         <div class="container line-info pt-md-0 pt-4" :key="index">
            <Breadcrumb v-if="element._group == 'banner'" :links="links" :title="element.title"/>
         </div>
      </template>

      <Footer/>
      <a class="backend-edit" :href="backendUrl" v-if="backendAdminLoggedIn">Edit page</a>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import Footer from "../_common/Footer";
   import Breadcrumb from "../_common/Breadcrumb";
   import MobileTitle from "../_common/MobileTitle";
   import ContentElements from "../_content_elements/ContentElements";

   export default {
      name: "Page",
      components: {
         Breadcrumb,
         Footer,
         MobileTitle,
         ContentElements
      },
      metaInfo() {
         return {
            title: this.page.bySlug?.meta?.title !== '' ? this.page.bySlug?.meta?.title : this.page.bySlug.title,
            titleTemplate: '%s | ' + this.settings.all.site_name,
            meta: [{
               name: 'description',
               content: this.page.bySlug?.meta?.description,
            }, {
               name: 'keywords',
               content: this.page.bySlug?.meta?.keywords,
            }]
         }
      },
      data() {
         return {
            pageLoaded: false,
            abellioSettings: {
               adminLoggedIn: true,
               backend_url: '--'
            },
            pageLinks: []
         }
      },
      computed: {
         ...mapState([
            'page',
            'settings',
            'authentication'
         ]),
         backendAdminLoggedIn() {
            return this.pageLoaded && this.authentication?.loggedIn && this.authentication?.user?.user?.view_admin_link;
         },
         backendUrl() {
            return this.authentication.user.user.edit_links.page + '/' + this.page.bySlug.id;
         },
         links() {
            let pageLinks = [];
            this.page.bySlug.parents.forEach(function (page) {
               pageLinks.push({
                  title: page.title,
                  path: page.link,
                  active: false
               });
            });

            pageLinks.push({
               title: this.page.bySlug.title,
               path: this.page.bySlug.slug,
               active: true
            });

            return [
               {
                  title: this.__t('homepage'),
                  path: '/',
                  active: false
               }
            ].concat(pageLinks);
         }
      },
      created() {
         if (this.$route.params.pathMatch === '/') {
            this.$router.push({name: 'home'});
         }
         if (this.$route.params.pathMatch) {
            let lastSlug = this.$route.params.pathMatch.replace(/\/$/, '');
            lastSlug = lastSlug.split('/');

            if (lastSlug.length) {
               let slug = {
                  slug: lastSlug[lastSlug.length - 1],
                  fullPath: this.$route.params.pathMatch
               }

               this.$store.dispatch('page/getBySlug', slug).then(() => {
                  this.pageLoaded = true;
               }, (error) => {
                  if (error.response.status === 404) {
                     if (error.response.data.redirect) {
                        if (error.response.data.redirect.new_url.startsWith('http')) {
                           window.location.href = error.response.data.redirect.new_url
                        } else {
                           this.$router.push(error.response.data.redirect.new_url);
                        }
                     } else {
                        this.$router.replace({
                           name: '404', params: {
                              locale: this.$route.params.locale ? this.$route.params.locale : null
                           }
                        });
                     }
                  }
               });
            }
         }
      },
   }
</script>

<style lang="scss" scoped>
   .backend-edit-button {
      position: fixed;
      background: $abellio-red;
      bottom: 20px;
      right: 20px;
      padding: 10px 25px;
      z-index: 100;
      color: #FFF;
      font-weight: bold;
      -webkit-box-shadow: 0 0 40px -14px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0 0 40px -14px rgba(0, 0, 0, 0.75);
      box-shadow: 0 0 40px -14px rgba(0, 0, 0, 0.75);
   }
</style>
